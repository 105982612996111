import { integrationService, reportService } from "/app/src/services";
import { Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { useCallback } from "react";
import useSetting from "/app/src/components/settings/setting";
import { getChangedValues } from "/app/src/helpers/forms";
import {
  convertBooleanToString,
  convertStringToBoolean,
} from "/app/src/helpers";

interface GeneralSettingsFormValues {
  number?: string | number;
  token?: string;
  reportId?: number;
  creationMode?: string;
  values?: (string | number)[];
  value?: boolean;
  name?: string;
}
/**
 * DataHook for the settings of the integration
 */
export default function DataHook(integration: Integration) {
  const queryClient = useQueryClient();
  const {
    settingValue: creationMode,
    createUpdateSetting: createUpdateCreationMode,
  } = useSetting({
    integrationId: integration.id,
    type: "creationMode",
  });
  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (values: Integration) => {
      return integrationService
        .updateSingle(integration.id, values)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(
        ["integration", Number(integration.id)],
        response,
      );
    },
  });
  const {
    settingValue: internalMappingSettingValue,
    createUpdateSetting: createUpdateInternalMapping,
  } = useSetting({
    integrationId: integration.id,
    type: "internalNameMapping",
  });

  const {
    settingValue: autoSendSettingValue,
    createUpdateSetting: createUpdateAutoSend,
  } = useSetting({
    integrationId: integration.id,
    type: "autoSend",
  });
  const handleSubmit = useCallback(
    async (values: GeneralSettingsFormValues) => {
      const changedIntegrationValues = getChangedValues(
        {
          name: values.name,
          number: values.number,
          token: values.token,
          reportId: values.reportId,
        },
        integration,
        ["name", "number", "token", "reportId"],
      );
      if (Object.keys(changedIntegrationValues).length) {
        await updateIntegration(changedIntegrationValues);
      }

      if (values.creationMode !== creationMode) {
        await createUpdateCreationMode({
          value: values.creationMode,
          type: "creationMode",
          integrationId: integration.id,
        });
      }

      if (values.values.join(";") !== internalMappingSettingValue) {
        await createUpdateInternalMapping({
          value: values.values.join(";"),
          type: "internalNameMapping",
          integrationId: integration.id,
        });
      }
      if (values.value !== convertStringToBoolean(autoSendSettingValue)) {
        await createUpdateAutoSend({
          value: convertBooleanToString(values.value),
          type: "autoSend",
          integrationId: integration.id,
        });
      }
    },
    [
      autoSendSettingValue,
      createUpdateAutoSend,
      createUpdateCreationMode,
      createUpdateInternalMapping,
      creationMode,
      integration,
      internalMappingSettingValue,
      updateIntegration,
    ],
  );

  const { data: reports } = useQuery({
    queryKey: [
      "reports",
      "[or]LocationContentBreakdown;LocationContent;History;Material",
    ],
    queryFn: () =>
      reportService.getAll(
        buildParams({
          baseTable:
            "[or]LocationContentBreakdown;LocationContent;History;Material",
        }),
      ),
    initialData: { reports: [] },
    select: (data) => data.reports,
  });

  return {
    reports,
    handleSubmit,
    creationMode,
    internalMappingSettingValue,
    autoSendSettingValue,
  };
}
