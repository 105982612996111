import { Col, Row } from "antd";
import { Integration, iField } from "/app/src/models";
import { useTranslation } from "react-i18next";
import NextDivider from "/app/src/components/NextUi/Divider";
import Box from "/app/src/components/generic/components/box";
import Field from "./field";
import { orderBuilderLineFields as allFields } from "/app/src/constants/orderBuilderFields";
/**
 * Renders the line fields component.
 *
 * @param integration - The integration object.
 * @param lineFields - The array of line fields.
 * @param createField - The function to create a new field.
 * @param updateField - The function to update an existing field.
 */
export default function LineFields({
  integration,
  lineFields,
  createField,
  updateField,
}: {
  integration: Integration;
  lineFields: iField[];
  createField: (data: iField) => Promise<void>;
  updateField: (data: iField) => Promise<void>;
}) {
  const { t } = useTranslation();

  return (
    <Box>
      <Row className="pb-3">
        <Col className="text-lg" span={8}>
          {t("translation:order_lines")}
        </Col>
      </Row>
      <NextDivider />
      {allFields.map((field) => {
        return (
          <Field
            key={field.value}
            iField={
              lineFields.find((f) => f.name === field.value)
                ? lineFields.find((f) => f.name === field.value)
                : { name: field.value }
            }
            integration={integration}
            createField={createField}
            updateField={updateField}
            level={"line"}
          />
        );
      })}
    </Box>
  );
}
