export const orderBuilderFields = [
  { label: "Order Name", value: "name" },
  { label: "Deadline", value: "deadline" },
  { label: "Info1", value: "Info1" },
  { label: "Info2", value: "Info2" },
  { label: "Info3", value: "Info3" },
  { label: "Info4", value: "Info4" },
  { label: "Info5", value: "Info5" },
  { label: "Owner", value: "owner" },
  { label: "Priority", value: "priority" },
  { label: "Recipient", value: "recipient" },
  { label: "Warehouse Name", value: "warehouseName" },
  { label: "Initial Handling Unit", value: "initialHandlingUnit" },
];

export const orderBuilderLineFields = [
  { label: "Material ID", value: "materialId" },
  { label: "Material", value: "materialName" },
  { label: "Material Code", value: "materialCode" },
  { label: "Quantity", value: "quantity" },
  { label: "Name/Number", value: "number" },
  { label: "Expiry Date", value: "expiryDate" },
  { label: "Host Identification", value: "hostIdentification" },
  { label: "Info1", value: "Info1" },
  { label: "Info2", value: "Info2" },
  { label: "Info3", value: "Info3" },
  { label: "Info4", value: "Info4" },
  { label: "Info5", value: "Info5" },
  { label: "Lot", value: "lot" },
  { label: "Production Date", value: "productionDate" },
  { label: "Qualification", value: "qualification" },
  { label: "Release Date", value: "releaseDate" },
  { label: "Serial Number", value: "serialNumber" },
];

//export both constants
export const allOrderBuilderFields = orderBuilderFields.concat(
  orderBuilderLineFields,
);
