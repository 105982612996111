import { Form, Select } from "formik-antd";
import { Integration, ReportColumn as ReportColumnT } from "/app/src/models";
import { useQuery } from "@tanstack/react-query";
import { reportColumnService } from "/app/src/services";
import { buildParams } from "/app/src/helpers";
import { useTranslation } from "react-i18next";

/**
 *
 * @returns ReportColumn component
 */
export default function ReportColumn({
  integration,
  dynamic = false,
  allowCurrentTime = false,
  label = undefined,
  size = "middle",
}: {
  integration: Integration;
  dynamic?: boolean;
  allowCurrentTime?: boolean;
  label?: string;
  size?: "middle" | "large" | "small";
}) {
  const { t } = useTranslation();
  const { data: columns } = useQuery({
    queryKey: ["columns", integration.reportId],
    queryFn: () => {
      return reportColumnService.getAll(
        buildParams({ reportId: integration.reportId }),
      );
    },
    initialData: { report_columns: [] },
    select: (data: { report_columns: ReportColumnT[] }) => {
      return data.report_columns;
    },
  });
  return (
    <Form.Item
      className="w-full !mb-0 "
      name={dynamic ? "values" : "columnId"}
      hasFeedback={false}
      label={label && label}
    >
      <Select
        size={size}
        name={dynamic ? "values" : "columnId"}
        placeholder={t("translation:select_column")}
        mode={dynamic ? "tags" : undefined}
      >
        {columns.map((column) => (
          <Select.Option value={column.id} key={column.id}>
            {column.name}
          </Select.Option>
        ))}
        {allowCurrentTime && (
          <Select.Option value="[currentTime]" key="[currentTime]">
            {`[${t("translation:current_time")}]`}
          </Select.Option>
        )}
      </Select>
    </Form.Item>
  );
}
