import { iOrder as iOrderType } from "/app/src/models";
import { useNumberId } from "/app/src/hooks/useNumberId";
import { iOrderService } from "/app/src/services";
import { useQuery } from "@tanstack/react-query";

import { useTranslation } from "react-i18next";
import Revisions from "./revisions";
import Lines from "./lines";
import OrderDetails from "./orderDetails";

/**
 * Displays the order details of an order created through the Order Builder App
 */
export default function OrderBuildOrder() {
  const { orderId } = useNumberId();
  const { t } = useTranslation();

  const { data: iOrder } = useQuery({
    queryKey: ["order", orderId],
    queryFn: () => {
      return iOrderService.getSingle(orderId);
    },
    initialData: { order: { loadedData: {}, lines: [] } },
    select: (data: { order: iOrderType }) => {
      return data.order;
    },
  });

  return (
    <>
      <OrderDetails iOrder={iOrder} />
      <Lines order={iOrder} />
      <h2>{t("translation:history")}</h2>
      <Revisions order={iOrder} />
    </>
  );
}
