import React from "react";
import Tip from "/app/src/components/NextUi/Tip";
import {
  ArrowRight,
  ArrowCircleRight,
  ExclamationCircle,
  Delete,
  Warning,
  ArrowBoxRight,
  Locked,
  Unlocked,
  DragIndicator,
  Checkmark,
  Undo,
  Plus,
  StarOutlined,
  StarFilled,
  Close,
  InfoCircle,
  QuestionCircle,
  PlusCircle,
  Edit,
  CircleCheck,
  Clip,
  Menu,
  Globe,
  User,
  WifiOff,
  ChevronLeft,
  Calendar,
  Refresh,
  Drag,
} from "/app/src/components/icons/icons";

interface IconBuilderProps {
  icon:
    | "ArrowRight"
    | "ArrowCircleRight"
    | "ExclamationCircle"
    | "Delete"
    | "Warning"
    | "ArrowBoxRight"
    | "Locked"
    | "Unlocked"
    | "DragIndicator"
    | "Checkmark"
    | "Undo"
    | "Plus"
    | "StarOutlined"
    | "StarFilled"
    | "Close"
    | "InfoCircle"
    | "QuestionCircle"
    | "PlusCircle"
    | "Edit"
    | "CircleCheck"
    | "Clip"
    | "Menu"
    | "Globe"
    | "User"
    | "WifiOff"
    | "ChevronLeft"
    | "Calendar"
    | "Refresh"
    | "Drag";
  size?: number;
  color?: string;
  onClick?: (() => void) | ((e: React.MouseEvent) => void);
  className?: string;
}

interface IconToolTipProps {
  content: string;
  icon?:
    | "ArrowRight"
    | "ArrowCircleRight"
    | "ExclamationCircle"
    | "Delete"
    | "Warning"
    | "ArrowBoxRight"
    | "Locked"
    | "Unlocked"
    | "DragIndicator"
    | "Checkmark"
    | "Undo"
    | "Plus"
    | "StarOutlined"
    | "StarFilled"
    | "Close"
    | "InfoCircle"
    | "QuestionCircle"
    | "PlusCircle"
    | "Edit"
    | "CircleCheck"
    | "Clip"
    | "Menu"
    | "Globe"
    | "User"
    | "WifiOff"
    | "ChevronLeft"
    | "Calendar"
    | "Refresh"
    | "Drag";
  size?: number;
  color?: string;
  onClick?: () => void;
  tipPlacement?:
    | "top"
    | "bottom"
    | "right"
    | "left"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end";
  className?: string;
}

/**
 *
 * @param iconName string
 * @returns getIcon
 */
const getIcon = (iconName: string, size: number, color: string) => {
  switch (iconName) {
    case "ArrowRight":
      return <ArrowRight size={size} color={color} />;
    case "ArrowCircleRight":
      return <ArrowCircleRight size={size} color={color} />;
    case "ExclamationCircle":
      return <ExclamationCircle size={size} color={color} />;
    case "Delete":
      return <Delete size={size} color={color} />;
    case "Warning":
      return <Warning size={size} color={color} />;
    case "ArrowBoxRight":
      return <ArrowBoxRight size={size} color={color} />;
    case "Locked":
      return <Locked size={size} color={color} />;
    case "Unlocked":
      return <Unlocked size={size} color={color} />;
    case "DragIndicator":
      return <DragIndicator size={size} color={color} />;
    case "Checkmark":
      return <Checkmark size={size} color={color} />;
    case "Undo":
      return <Undo size={size} color={color} />;
    case "Plus":
      return <Plus size={size} color={color} />;
    case "StarOutlined":
      return <StarOutlined size={size} color={color} />;
    case "StarFilled":
      return <StarFilled size={size} color={color} />;
    case "Close":
      return <Close size={size} color={color} />;
    case "InfoCircle":
      return <InfoCircle size={size} color={color} />;
    case "QuestionCircle":
      return <QuestionCircle size={size} color={color} />;
    case "PlusCircle":
      return <PlusCircle size={size} color={color} />;
    case "Edit":
      return <Edit size={size} color={color} />;
    case "CircleCheck":
      return <CircleCheck size={size} color={color} />;
    case "Clip":
      return <Clip size={size} color={color} />;
    case "Menu":
      return <Menu size={size} color={color} />;
    case "Globe":
      return <Globe size={size} color={color} />;
    case "User":
      return <User size={size} color={color} />;
    case "WifiOff":
      return <WifiOff size={size} color={color} />;
    case "ChevronLeft":
      return <ChevronLeft size={size} color={color} />;
    case "Calendar":
      return <Calendar size={size} color={color} />;
    case "Refresh":
      return <Refresh size={size} color={color} />;
    case "Drag":
      return <Drag size={size} color={color} />;
    default:
      return null;
  }
};

/**
 *
 * @param param0 icon, size and color
 * @returns Icon
 */
const IconBuilder: React.FC<IconBuilderProps> = ({
  icon,
  size = 24,
  color = "#202023",
  onClick,
  className,
}) => {
  const Icon = getIcon(icon, size, color);
  return !onClick ? (
    <div className={className}>{Icon}</div>
  ) : (
    <button className={className} onClick={onClick}>
      {Icon}
    </button>
  );
};

const IconToolTip: React.FC<IconToolTipProps> = ({
  content,
  icon = "QuestionCircle",
  size = 18,
  color = "#202023",
  onClick,
  tipPlacement = "top",
  className,
}) => {
  const Icon = getIcon(icon, size, color);

  return !onClick ? (
    <Tip placement={tipPlacement} content={content}>
      <div className={className}>{Icon}</div>
    </Tip>
  ) : (
    <button className={className} onClick={onClick} onKeyDown={onClick}>
      <Tip placement={tipPlacement} content={content}>
        <div>{Icon}</div>
      </Tip>
    </button>
  );
};

export { IconBuilder, IconToolTip };
