import React, { useCallback, useMemo } from "react";
import { Formik, FormikHelpers, FormikProps } from "formik";
import NextSwitch from "/app/src/components/NextUi/Switch";
import { Row, Col } from "antd";
import { Form, Select, SubmitButton, Input } from "formik-antd";
import { useTranslation } from "react-i18next";
import { integrationService, connectionService } from "/app/src/services";
import Headers from "/app/src/components/generic/components/apps/data/settings/headers";
import { Integration } from "/app/src/models";
import { buildParams } from "/app/src/helpers/params";
import ConnectionSettings from "/app/src/components/generic/components/apps/data/settings/connectionSettings";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { handlePromiseError } from "/app/src/helpers/api";
import { isAPIType, isSQLType, simpleSchemaBuilder } from "/app/src/helpers";
import Request from "./request";
import AutoDetectDirection from "./autoDetectDirection";
import DefaultMaterialProperty from "./defaultMaterialProperty";
import SQLGroupBy from "/app/src/components/connection/sql/sqlGroupBy";
import DateTimeFormat from "./dateTimeFormat";
import Filters from "/app/src/components/connection/sql/filters";
import DefaultDirection from "./defaultDirection";
import AppendLines from "./appendLines";
import EnableManual from "./enableManual";
import FlatGroupBy from "./flatGroupBy";
import useSetting from "/app/src/components/settings/setting";
import Box from "/app/src/components/generic/components/box";
import { IconToolTip } from "/app/src/components/icons/IconBuilder";
import ClearLogs from "./clearLogs";
import ClearTasks from "./clearTasks";
import { sqlIntegrationSchema } from "/app/src/schemas/apps/dataPull/integrationSchema";

/**
 * Format the form value keys to match the API Model
 */
function formatForm(values: IntegrationFormValues): Integration {
  const ret = {
    name: values.name,
    baseTable: values.baseTable,
    type: values.type,
  };
  if (values.token) {
    ret["token"] = values.token;
  }
  if (values.number) {
    ret["number"] = values.number;
  }
  return ret;
}

interface IntegrationFormValues {
  name: string | undefined;
  baseTable: string | undefined;
  token?: string;
  number?: string | number;
  type?: "create" | "delete";
}

/**
 * Component to display the settings for a single Data Pull Integration
 */
export default function Settings({
  integration,
}: {
  integration: Integration;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutateAsync: updateIntegration } = useMutation({
    mutationFn: (updatedIntegration: Integration) => {
      return integrationService
        .updateSingle(updatedIntegration.id, updatedIntegration)
        .then(handlePromiseError);
    },
    onSuccess: (response) => {
      queryClient.setQueryData(["integration", integration.id], response);
    },
  });
  const { settingValue: groupBySetting } = useSetting({
    integrationId: integration.id,
    type: "groupBy",
  });
  const baseTables = useMemo(
    () => [
      { label: "Material", value: "Material" },
      { label: "Order", value: "Order" },
    ],
    [],
  );
  /**
   * Update the integration
   */
  const updateIntegrationHandler = useCallback(
    async (
      values: IntegrationFormValues,
      actions: FormikHelpers<IntegrationFormValues>,
    ) => {
      if (integration?.id) {
        await updateIntegration({
          id: integration.id,
          ...formatForm(values),
        }).then(() => {
          actions.resetForm();
        });
      }
    },
    [integration.id, updateIntegration],
  );

  /**
   * Toggle the integration status
   */
  const toggleIntegrationStatus = useCallback(
    (value: boolean) => {
      //value is a bool - convert to number
      updateIntegration({ id: integration.id, status: Number(value) });
    },
    [integration, updateIntegration],
  );

  const { data: connections } = useQuery({
    queryKey: ["connections", "[or]REST;GraphQL;MSSQL;IBM2"],
    queryFn: () => {
      return connectionService.getAll(
        buildParams({ type: "[or]REST;GraphQL;MSSQL;IBM2" }),
      );
    },
    initialData: { connections: [] },
    select: (data = { connections: [] }) => {
      return data.connections;
    },
  });

  /*
   * Formik form for updating the integration
   */
  const updateIntegrationForm: (
    props: FormikProps<IntegrationFormValues>,
  ) => JSX.Element = useCallback(
    ({ dirty, isSubmitting, values, isValid }) => (
      <Form layout="vertical">
        <Row justify="start" gutter={16}>
          <Col span={4}>
            <Form.Item
              name="name"
              label={t("translation:integration_name")}
              hasFeedback={false}
            >
              <Input
                name="name"
                placeholder={t("translation:enter_name")}
                size="large"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="baseTable" label={t("translation:type")}>
              <Select
                disabled
                name="baseTable"
                size="large"
                placeholder={t("translation:select_type")}
              >
                {baseTables.map((t) => (
                  <Select.Option value={t.value} key={t.value}>
                    {t.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item name="type" label={t("translation:method")}>
              <Select
                disabled
                name="type"
                size="large"
                placeholder={t("translation:select_type")}
              >
                {values.baseTable === "Order" && (
                  <Select.Option value={"delete"} key={"delete"}>
                    {"Deletion"}
                  </Select.Option>
                )}

                <Select.Option value={"create"} key={"create"}>
                  {"Creation"}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {isSQLType(integration.connectionType) ? (
            <Col span={6}>
              <Form.Item
                name="token"
                label={t("translation:table_name")}
                hasFeedback={false}
              >
                <Input
                  name="token"
                  placeholder={t("translation:enter_name")}
                  size="large"
                />
              </Form.Item>
            </Col>
          ) : (
            <Col span={6}>
              <Form.Item
                name="number"
                label={t("translation:order_structure")}
                hasFeedback={false}
              >
                <Select
                  name="number"
                  size="large"
                  placeholder={t("translation:nested")}
                >
                  <Select.Option value="nested" key="1">
                    {t("translation:nested")}
                  </Select.Option>
                  <Select.Option value="flat" key="2">
                    {t("translation:flat")}
                  </Select.Option>
                  <Select.Option value="flatten" key="3">
                    {t("translation:no_structure")}
                  </Select.Option>
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={4}>
            <SubmitButton
              type="primary"
              size="large"
              block
              disabled={!dirty || isSubmitting || !isValid}
              style={{ marginTop: "30px" }}
            >
              {t("translation:update")} {t("translation:integration")}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    ),
    [baseTables, integration.connectionType, t],
  );

  /**
   * Toggle the integration status
   */
  return (
    <>
      <Box>
        <Row>
          <Col span={23}>
            <h1>{t("translation:general")}</h1>
          </Col>
          <Col span={1}>
            {integration.number === "flat" && groupBySetting === undefined ? (
              <IconToolTip
                content={t("translation:group_by_not_set")}
                icon="Warning"
                size={32}
                color="#d0021b"
                className="flex justify-center items-center"
              />
            ) : (
              <>
                <h4>{t("translation:active")}:</h4>
                <NextSwitch
                  isSelected={integration?.status === 1}
                  onValueChange={toggleIntegrationStatus}
                />
              </>
            )}
          </Col>
        </Row>
        <Formik
          component={updateIntegrationForm}
          initialValues={{
            name: integration.name,
            baseTable: integration.baseTable,
            token: integration.token,
            number: integration.number,
            type: integration.type === "delete" ? "delete" : "create",
          }}
          validationSchema={
            ["MSSQL", "IBM2"].includes(integration.connectionType)
              ? sqlIntegrationSchema
              : simpleSchemaBuilder([
                  { name: "name", type: "string", required: true },
                  { name: "baseTable", type: "string", required: true },
                ])
          }
          enableReinitialize
          onSubmit={updateIntegrationHandler}
        />
      </Box>

      <ConnectionSettings
        integration={integration}
        maxRecords={false}
        retries={false}
        connections={connections}
      />
      {isAPIType(integration.connectionType) && (
        <>
          <Headers integration={integration} />
          {integration.connectionType === "GraphQL" && (
            <Request integration={integration} />
          )}
        </>
      )}
      {integration.type !== "delete" && (
        <Box>
          <h1>{t("translation:other_settings")}</h1>
          <Row gutter={16}>
            <Col span={12}>
              <DateTimeFormat integration={integration} />
            </Col>
            {integration.baseTable === "Order" && (
              <>
                <Col span={12}>
                  <AutoDetectDirection integration={integration} />
                </Col>
                <Col span={12}>
                  <DefaultDirection integration={integration} />
                </Col>
                <Col span={12}>
                  <AppendLines integration={integration} />
                </Col>
              </>
            )}
            {integration.baseTable === "Material" && (
              <Col span={12}>
                <DefaultMaterialProperty integration={integration} />
              </Col>
            )}
            <Col span={12}>
              <EnableManual integration={integration} />
            </Col>
            {isSQLType(integration.connectionType) ? (
              <>
                <Col span={12}>
                  <SQLGroupBy integration={integration} />
                </Col>
                <Col span={24}>
                  <h1>{t("translation:filters")}</h1>
                  <Filters integration={integration} />
                </Col>
              </>
            ) : (
              integration.number === "flat" && (
                <Col span={12}>
                  <FlatGroupBy integration={integration} />
                </Col>
              )
            )}
          </Row>
        </Box>
      )}
      <Box>
        <h1>{t("translation:maintenance")}</h1>
        <Row gutter={16}>
          <Col span={12}>
            <ClearLogs integration={integration} />
          </Col>
          <Col span={12}>
            <ClearTasks integration={integration} />
          </Col>
        </Row>
      </Box>
    </>
  );
}
